<template>
  <div>
    <el-dialog
      custom-class="infoSubmit"
      title=""
      :visible.sync="infoSubmit"
      width="563px"
      @close="orderShow = false"
    >
      <div class="formMain">
        <p>为了保证您的考试正常进行和资格证书领取请您完善以下信息</p>
        <div class="form">
          <div class="formLeft">
            <div class="line">
              <p>姓名</p>
              <span>:</span>
              <div><input v-model="form.name" placeholder="请输入您的姓名" /></div>
            </div>
            <div class="line">
              <p>性别</p>
              <span>:</span>
              <div>
                <p @click="form.gender = 1" :class="{ sexActive: form.gender == 1 }">
                  <img :src="form.gender == 1 ? radio2 : radio" />男
                </p>
                <p @click="form.gender = 2" :class="{ sexActive: form.gender == 2 }">
                  <img :src="form.gender == 2 ? radio2 : radio" />女
                </p>
              </div>
            </div>
            <div class="line">
              <p>手机号</p>
              <span>:</span>
              <div>{{ form.mobile }}</div>
            </div>
            <div class="line">
              <p>身份证号</p>
              <span>:</span>
              <div><input v-model="form.cid" placeholder="请输入您的身份证号" /></div>
            </div>
          </div>
          <div class="formRight">
            <img :src="form.cover || formImg" />
            <input type="file" accept=".png, .jpg, .jpeg" @change="uploadData" />
          </div>
        </div>

        <div class="btn" @click="formSubmit">确认并保存</div>
      </div>
    </el-dialog>
    <div class="examin-content" :class="{ bg2: !list }">
      <div class="examinLeft">
        <p class="examinTitle">{{ testInfo && testInfo.name }}考试试题</p>
        <p class="examinDesc">
          <img
            src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examin1.png"
          />满分{{ testInfo && testInfo.score }}分 试题{{
            testInfo.question_num
          }}道 {{ testInfo && testInfo.pass }}分及以上为合格
        </p>
        <p class="examinDesc" v-if="testInfo.test_time">
          <img
            src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examin2.png"
          />考试时间：{{ testInfo.test_time }}
        </p>
        <!-- <p class="score"><span>满分{{lesson.base.score}}分</span>试题数{{lesson.base.question_number}}</p> -->
      </div>
      <!-- :class="{opacity:!lesson.base.study_pass}" -->
      <div class="examinBtn" @click="toExamin">
        <div class="isPass" v-if="testInfo.test_time">
          <img :src="testInfo.test_status == 1 ? pass : noPass" />
        </div>
        <div class="btn">
          <p v-if="testInfo.test_time && testInfo.test_status == 0">重新考试</p>
          <p v-if="testInfo.test_time && testInfo.test_status == 1">查看试卷</p>
          <p v-if="!testInfo.test_time">开始考试</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["testInfo", "list"],
  data() {
    return {
      form: {
        gender: 2,
        name: "",
        mobile: this.$store.state.user.mobile,
        cid: "",
        cover: "",
      },
      formImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/formImg.jpg",
      radio:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/formRadio.jpg",
      radio2:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/formRadio2.jpg",
      infoSubmit: false,
      // testInfo:{
      //   exam_pass: 0,
      //   name:'试卷题目',
      //   score: 100,
      //   question_number: 10,
      //   pass: 0,
      //   exam_time: '2022-10-10 12:12:12'
      // },
      pass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/pass.jpg",
      noPass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/noPass.jpg",
    };
  },
  computed: {
    vipInfo() {
      return this.$store.state.vipInfo;
    },
    platName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    VIPShow() {
      return this.$store.state.VIPShow;
    },
    logo() {
      return this.$store.state.plat.logo;
      // return "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyulogo.png";
    },
  },
  mounted() {},
  methods: {
    uploadData(e) {
      console.log(e.target.files[0]);
      let file = e.target.files[0];
      // file.size < 1024 * 200 ||
      if (file.size > 1024 * 1024) {
        this.$toast("请上传符合要求的照片");
        return;
      }
      let param = new FormData();
      param.append("folder", file);
      this.$request.toUpload(param).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.form.cover = res.data.fullpath;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    formSubmit() {
      // if(this.$until.checkPhone(this.form.mobile)!='ok'){
      //   this.$toast(this.$until.checkPhone(this.form.mobile));
      //   return
      // }
      if(this.$until.checkCard(this.form.cid)!='ok'){
        this.$toast(this.$until.checkCard(this.form.cid));
        return
      }
      if (Object.values(this.form).findIndex((item) => !item) != -1) {
        this.$toast("请补全全部信息");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$request.userInfoSubmit(this.form).then((res) => {
        loading.close();
        if (res.code == 0) {
          this.$router.push({
            path: "./examinList",
            query: {
              id: this.testInfo.id,
              isExamin: true,
            },
          });
        } else {
          this.$toast(res.msg);
        }
      });
    },
    toExamin() {
      let isStudy = this.list
        ? this.list.every((item) => item.study_status == 1)
        : this.testInfo.is_test;
      let baseInfo = this.$parent.baseInfo || this.$parent.$parent.baseInfo;
      // if (!isStudy) {
        console.log(baseInfo)
      if (!isStudy) {
        this.$toast("您的课程还没有全部学习完成，请学习完全部课程后在参加考试");
        return;
      }
      if (!baseInfo.test_message && baseInfo.category_id!=225) {
        this.infoSubmit = true;
        return;
      }
      if (this.testInfo.test_time && this.testInfo.test_status == 1) {
        this.$router.push({
          path: "./examinList",
          query: {
            currentIndex: 0,
            id: this.testInfo.id,
            resultId: this.testInfo.report_id,
            discipline_id: baseInfo.discipline_id
          },
        });
      } else {
        this.$router.push({
          path: "./examinList",
          query: {
            id: this.testInfo.id,
            isExamin: true,
          },
        });
      }

      // } else {
      //   this.$toast("您还未满足考试要求");
      // }
    },
    toPage() {
      if (!localStorage.getItem("user")) {
        this.$store.commit("changeLoginShow", true);
      } else {
        this.$router.push({
          path: "./detailVip",
          query: {
            id: this.vipInfo.goods_id,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.formMain {
  color: #333333;
  overflow: hidden;
  > p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 36px;
  }
  .form {
    display: flex;
    .formLeft {
      padding-left: 40px;
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .line {
        display: flex;
        align-items: center;
        width: 316px;
        > p {
          font-size: 16px;
          width: 68px;
          text-align: justify;
          text-align-last: justify;
          margin-right: 3px;
        }
        > span {
          font-size: 16px;
        }
        > div {
          margin-left: 11px;
          padding-left: 10px;
          flex: 1;
          border-bottom: 1px solid #e4e8ed;
          line-height: 30px;
          height: 32px;
          display: flex;
          align-items: center;
          input {
            border: 0;
            height: 30px;
            background: transparent;
          }
          p {
            width: 79px;
            display: flex;
            align-items: center;
            color: #999999;
            cursor: pointer;
            img {
              margin-right: 8px;
            }
          }
          p.sexActive {
            color: #666;
          }
        }
      }
    }
    .formRight {
      width: 124px;
      height: 173px;
      position: relative;
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .btn {
    width: 260px;
    height: 36px;
    cursor: pointer;
    margin: 20px auto 0;
    background: #0080d6;
    color: #fff;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    border-radius: 18px;
  }
}
.examin-content {
  display: flex;
  align-items: center;
  width: 780px;
  margin: 0 10px;
  background: #e6f3fc;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 30px 20px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666666;
}
.bg2 {
  background: #f7f7f7;
}
.examin-content .examinBtn {
  width: 122px;
  text-align: center;
  padding: 10px 0;
  color: #fff;
  border-radius: 5px;
  .isPass {
    margin: 0 auto 20px;
    cursor: pointer;
    height: 37px;
  }
  .btn {
    width: 100%;
    height: 34px;
    line-height: 34px;
    background: #fff;
    color: #0080d6;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: #0080d6;
      color: #fff;
    }
  }
}
.opacity {
  opacity: 0.5;
}
.examin-content .examinLeft {
  flex: 1;
}
.examin-content .examinLeft .examinTitle {
  color: #000;
  width: 100%;
  font-size: 16px;
  margin-bottom: 16px;
}
.examin-content .examinLeft .examinDesc {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  img {
    margin-right: 8px;
  }
}
.examin-content .examinLeft .score span {
  margin-right: 50px;
}
</style>
