<template>
  <div v-loading="loading">
    <div class="examin-content" :class="{ bg2: !list }" v-if="testInfo">
      <!-- <div class="process" v-if="progress">
        <el-progress :percentage="progress" :indeterminate="true" />
      </div> -->
      <div class="examinLeft">
        <p class="examinTitle">{{ testInfo && testInfo.name }}</p>
        
        <p class="examinDesc" v-if="testInfo.time">
          <img
            src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examin2.png"
          />上传时间：{{ testInfo.time }}
        </p>
      </div>
      <p v-if="testInfo.status_name == '未上传'">{{ testInfo.status_name }}</p>
      <p class="red" v-else-if="testInfo.status_name == '不通过'">{{ testInfo.status_name }}</p>
      <p class="green" v-else>{{ testInfo.status_name }}</p>
      <div class="examinBtn">
        <!-- <div class="isPass" v-if="testInfo.test_time">
          <img :src="testInfo.test_status == 1 ? pass : noPass" />
        </div> -->
        <div class="btn">
          <p v-if="!testInfo.time">上传视频</p>
          <p v-else-if="testInfo.status_name == '未通过' || testInfo.status_name == '待审核'">重新上传</p>
          <p v-else @click="toView">查看视频</p>
          <input @click="uploadData2" v-if="!status"/>
          <input type="file" ref="videoFile" accept=".mp4,.AVI,.mov,.rmvb,.rm,.FLV" @change="uploadData" v-else-if="status && (testInfo.status_name == '未上传' || testInfo.status_name == '待审核' || testInfo.status_name == '未通过')"/>
        </div>
      </div>
    </div>
  </div>
</template>  
<script>
export default {
  props: ["testInfo", "list"],
  data() {
    return {
      progress: 0,
      loading: false,
      form: {
        gender: 2,
        name: "",
        mobile: this.$store.state.user.mobile,
        cid: "",
        cover: "",
      },
      formImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/formImg.jpg",
      radio:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/formRadio.jpg",
      radio2:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/formRadio2.jpg",
      infoSubmit: false,
      // testInfo:{
      //   exam_pass: 0,
      //   name:'试卷题目',
      //   score: 100,
      //   question_number: 10,
      //   pass: 0,
      //   exam_time: '2022-10-10 12:12:12'
      // },
      pass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/pass.jpg",
      noPass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/noPass.jpg",
    };
  },
  computed: {
    status(){
      let status = false
      if(this.list){
        status = this.list.length ? this.list.every(item=>{
          return item.study_status == 1 || item.speak.length && item.speak.every(v=>{ return v.study_status == 1})
        }) : false
      
      }else{
        status = true
      }
      return this.testInfo.study_status == 1 || status
    },
    vipInfo() {
      return this.$store.state.vipInfo;
    },
    platName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    VIPShow() {
      return this.$store.state.VIPShow;
    },
   
  },
  mounted() {},
  methods: {
     gettime(video, size){
      const promise = new Promise(resolve => {
        video.addEventListener('canplaythrough', e => {
          if (e.target.duration <= size) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
      return promise
    },
    async checkSize(files, size) {
      // console.log(56, Number.isNaN(Number(size)), size)
      if (!files) return false
      // 这一条是正式服务器不需要这段所以当size 为undefined 时默认 返回 true
      if (Number.isNaN(Number(size))) return true
      const checktimevideo = document.getElementById('checktimevideo')
      if (checktimevideo) {
        document.body.removeChild(checktimevideo)
      }
      const video = document.createElement('video')
      const url = URL.createObjectURL(files)
      video.src = url
      video.id = 'checktimevideo'
      video.style.display = 'none'

      document.body.appendChild(video)

      return await this.gettime(video, size)
    },
    uploadData2(){
      this.$toast("请完成所有课程再提交");
    },
    toView(){
      window.open(this.testInfo.video_url)
    },
    async uploadData(e) {
      console.log(e);
   
     let ifFit = await this.checkSize(e.target.files[0], 300)
     if(!ifFit){
      this.$toast("请上传5分钟内的视频");
      return
     }
     
      
      let file = e.target.files[0];
      // file.size < 1024 * 200 ||
      if (file.size > 1024 * 1024 * 200) {
        this.$toast("请上传200M以内的视频");
        return;
      }
      let param = new FormData();
      param.append("folder", file);
      param.append("chapter_id", this.testInfo.id)
      param.append("token", localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).access_token : '')
      
      if(this.list){
        this.$parent.$parent.videoUpload()
      }else{
        this.$parent.videoUpload()
      }
      this.$request.toUploadVideo(param).then((res) => {
        this.$refs.videoFile.value = ''
        if (res && res.code == 0) {
          this.form.cover = res.data.fullpath;
          this.$request.videoExamin({
            discipline_id: this.testInfo.discipline_id,
            url: res.data,
            chapter_id: this.testInfo.id,
            token: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).access_token : ''
          }).then(res2=>{
            this.loading = false
            if(res2.code == 0){
              if(this.list){
                this.$parent.$parent.getData('video')
              }else{
                this.$parent.getData('video')
              }
              
            }else{
              if(this.list){
                this.$parent.$parent.videoUploadError()
              }else{
                this.$parent.videoUploadError()
              }
              this.$toast(res2.msg);
            }
          })
        } else {
          if(this.list){
            this.$parent.$parent.videoUpload('close')
          }else{
            this.$parent.videoUpload('close')
          }
          this.$toast(res.msg);
        }
      });
    },
    
    toPage() {
      if (!localStorage.getItem("user")) {
        this.$store.commit("changeLoginShow", true);
      } else {
        this.$router.push({
          path: "./detailVip",
          query: {
            id: this.vipInfo.goods_id,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">

.examin-content {
  display: flex;
  align-items: center;
  width: 780px;
  margin: 0 10px;
  background: #e6f3fc;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 30px 20px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666666;
  position: relative;
  .process{
    position: absolute;
    left: 20px;
    width: 760px;
    bottom: 10px;
  }
  >p{
    text-align: center;
    width: 30%;
  }
  p.green{
    color: #5FD131;
  }
  p.red{
    color: #FF4D41;
  }
}
.bg2 {
  background: #f7f7f7;
}
.examin-content .examinBtn {
  width: 122px;
  text-align: center;
  padding: 10px 0;
  color: #fff;
  border-radius: 5px;
  position: relative;
    input{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
      opacity: 0;
    }
  .isPass {
    margin: 0 auto 20px;
    cursor: pointer;
    height: 37px;
  }
  
  .btn {
    width: 100%;
    height: 34px;
    line-height: 34px;
    background: #fff;
    color: #0080d6;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: #0080d6;
      color: #fff;
    }
    
  }
}
.opacity {
  opacity: 0.5;
}
.examin-content .examinLeft {
  flex: 1;
}
.examin-content .examinLeft .examinTitle {
  color: #000;
  width: 100%;
  font-size: 16px;
  margin-bottom: 16px;
}
.examin-content .examinLeft .examinDesc {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  img {
    margin-right: 8px;
  }
}
.examin-content .examinLeft .score span {
  margin-right: 50px;
}
</style>
